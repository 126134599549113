<template>
  <sheet dense depressed light outlined>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template #body="data">
        <slide-y-up-transition :delay="300" group tag="tbody">
          <template v-for="(item, index) in data.items">
            <item-row
              :key="`invoice-row-${uid(index)}`"
              :can-add="index === 0 && !readOnly"
              :can-delete="data.items.length > 1"
              :disabled="readOnly"
              :headers="data.headers"
              :value="item"
              @del="deleteItemPosition(index)"
              @update="updateItemPosition($event, index)"
              @add:empty="addEmptyItemPosition"
            />
          </template>
        </slide-y-up-transition>
      </template>
    </v-data-table>
  </sheet>
</template>

<script lang="ts">
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import DataTableMixin, { DataTableHeader } from "@/mixins/DataTableMixin";

import ItemRow from "@/modules/invoices/components/rows/ItemRow.vue";
import { SlideYUpTransition } from "vue2-transitions";
import { find, findIndex, map } from "lodash";
import { InvoiceType } from "@planetadeleste/vue-mc-gw";

@Component({
  components: { ItemRow, SlideYUpTransition },
})
export default class InvoiceItems extends Mixins(DataTableMixin, InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;

  headers: DataTableHeader[] = [
    { text: "product", value: "offer" },
    { text: "name", value: "name" },
    { text: "quantity", value: "quantity", class: "w-100" },
    { text: "unit.price", value: "price" },
    // { text: "tax.type", value: "tax_type" },
    // { text: "tax.price", value: "tax_price" },
    { text: "amount", value: "total_price_with_tax" },
  ];

  get items() {
    return this.positions;
  }

  get companySettings() {
    return this.company.get("settings");
  }

  get hasDiscountsPerLine(): boolean {
    return this.companySettings
      ? this.$_.get(this.companySettings, "discounts_per_line", false)
      : false;
  }

  @Watch("priceWithTax")
  onChangePriceWithTax() {
    const iPriceIndex = findIndex(this.headers, { value: "price" });
    const obPriceHeader: DataTableHeader | undefined = find(this.headers, {
      value: "price",
    });

    if (iPriceIndex !== -1 && obPriceHeader) {
      const sLabel = this.priceWithTax ? "with" : "without";
      obPriceHeader.text = this.$t(`unit.price.${sLabel}.tax`) as string;

      this.headers.splice(iPriceIndex, 1, obPriceHeader);
    }
  }

  mounted() {
    // Remove name column
    // if (this.signed) {
    //   const iNameIndex = findIndex(this.headers, { value: "name" });
    //   if (iNameIndex !== -1) {
    //     this.headers.splice(iNameIndex, 1);
    //   }
    // }

    // Remove prices for eRemito (181) invoice type
    if (this.sTypeCode === InvoiceType.CODE_EREMITO) {
      const iPriceIndex = findIndex(this.headers, { value: "price" });
      if (iPriceIndex !== -1) {
        this.headers.splice(iPriceIndex, 1);
      }

      const iAmountIndex = findIndex(this.headers, {
        value: "total_price_with_tax",
      });
      if (iAmountIndex !== -1) {
        this.headers.splice(iAmountIndex, 1);
      }
    }

    // Add Discounts column
    if (this.hasDiscountsPerLine) {
      const arDiscountsHeader: DataTableHeader = {
        text: "discounts",
        value: "discounts",
      };

      this.headers.splice(this.headers.length - 1, 0, arDiscountsHeader);
    }

    // Add tax type
    if (this.isReceived) {
      this.headers.push({ text: "tax.type", value: "tax_type" });
    }

    this.headers = map(this.headers, (obItem: DataTableHeader) => {
      if (obItem.value === "price") {
        const sLabel = this.priceWithTax ? "with" : "without";
        obItem.text = `unit.price.${sLabel}.tax`;
      }

      return obItem;
    });

    if (!this.readOnly) {
      this.addDTActionsHeader();
    }

    this.mapDTHeaders();
    this.resetPositions();
    if (!this.signed && !this.obInvoice.id) {
      this.addEmptyItemPosition();
    }
  }

  uid(iValue: number) {
    const obPosition = this.$_.get(this.items, iValue);

    if (!obPosition) {
      return this.$_.uniqueId();
    }

    if (obPosition.get("idx")) {
      return obPosition.get("idx");
    }

    if (obPosition.id) {
      return obPosition.id;
    }

    return this.$_.uniqueId();
  }
}
</script>
